import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useRouter } from 'next/router'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { ProductKey } from '@/types/index'
import CardMedia from '@mui/material/CardMedia'
import { useAppStore } from '@/hooks/useStore'
import { useTranslations } from 'next-intl'
import { DateFormat, formatDateTime } from '@/utils/dates'
import { calculateTotalPrice, createProductName } from '../../utils/index'

interface Props {
  customBooking?: boolean
  product: ProductKey
}

const BookingRecap = ({ customBooking, product }: Props) => {
  const { locale } = useRouter()

  const bookingForm = useAppStore(state => state.bookingForm)
  const isAdrenalinePack = useAppStore(state =>  state.isAdrenalinePack)
  const customBookingForAdrenalinePack = customBooking && isAdrenalinePack
  const { email, phoneNumber } = bookingForm
  const { selectedSlots, groupSize, datePickerDate } = useAppStore(state => state.products[product as ProductKey])

  const productName = createProductName(product, locale!)
  const index = useTranslations('Index')
  const confirmation = useTranslations('Confirmation')
  const slotTimes = selectedSlots.map(slot => slot.time)
  // TODO: check type of selectedSlots[0]?.date , passing it to getFullDate fn leads to error for some reason
  const startsAt = formatDateTime(customBooking ? datePickerDate : selectedSlots[0]?.date, DateFormat.DayMonthYear, locale)
  const totalPrice = calculateTotalPrice(groupSize, selectedSlots[0])
  const productImagePath = `/images/product_image_${product.toLowerCase()}.jpg`

  return (
    <Box mt={4}>
      <Card
        sx={{
          display: 'flex',
          backgroundColor: 'secondary.light',
          flexDirection: { xs: 'row', sm: 'row' },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            display: { xxs: 'block', xs: 'block' },
            width: { xxs: '125px', xs: '155px', sm: '250px' },
          }}
          image={productImagePath}
          alt="Live from space album cover"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="subtitle1" color="text.primary" component="div">
              {index('product')}: {productName}
            </Typography>

            {!customBookingForAdrenalinePack &&
            <Typography variant="subtitle1" color="text.primary" component="div">
              {index('groupSizeTitle')}: {groupSize}
            </Typography>
            }
            <Typography variant="subtitle1" color="text.primary" component="div">
              {index('startsAtDate')}: {startsAt}
            </Typography>
            {!customBooking && (
              <Box>
                <Typography variant="subtitle1" color="text.primary" component="div">
                  {index('startTime')}: {slotTimes.join(', ')}
                </Typography>
                <Typography variant="subtitle1" color="text.primary" component="div">
                  {index('priceTitle')}: {totalPrice} {index('currency')}
                </Typography>
              </Box>
            )}
            {phoneNumber && (
              <Typography variant="subtitle1" color="text.primary" component="div">
                {confirmation('phone')}: {phoneNumber}
              </Typography>
            )}
            {email && (
              <Typography variant="subtitle1" color="text.primary" component="div">
                {confirmation('email')}: {email}
              </Typography>
            )}
          </CardContent>
        </Box>
      </Card>
    </Box>
  )
}

export default BookingRecap
