import { create } from 'zustand'
import { BookingFormValues, ProductKey, ProductBookingData, BookingInformation } from 'types'
import { defaultCalendarConfig } from '../../config'

export interface AppState {
  increaseGroupSize: (product: ProductKey) => void
  decreaseGroupSize: (product: ProductKey) => void
  selectedProductsSortedByStartsAt: () => any[]
  selectedProducts: ProductKey[] | []
  isAdrenalinePack: boolean
  products: Record<ProductKey, ProductBookingData>
  bookingForm: BookingFormValues
  bookingInformation: BookingInformation[] | []
  depositPaymentUrl: string
}

const createDefaultProductState = (): ProductBookingData => ({
  datePickerDate: new Date(),
  groupSize: 2,
  selectedSlots: [],
  numberOfSlots: 0,
  startsAt: null,
  calendarConfig: defaultCalendarConfig,
})

// https://codesandbox.io/s/zustand-counter-81n28?file=/src/store.ts:364-369

export const useAppStore = create<AppState>((set, get) => ({
  numberOfSlots: 0,
  selectedProducts: [],
  isAdrenalinePack: false,
  products: {
    DUNGEON: createDefaultProductState(),
    EXPERIMENT: createDefaultProductState(),
  },
  bookingForm: {
    fullName: '',
    email: '',
    phoneNumber: '',
    voucherCode: '',
    note: '',
  },
  bookingInformation: [],
  depositPaymentUrl: '',
  // Take products, filter those not selected, sort by startsAt and return the keys sorted
  selectedProductsSortedByStartsAt: () =>
    Object.entries(get().products)
      .filter(([key, product]) => product.startsAt !== null && product.startsAt !== undefined)
      .sort(
        ([keyA, productA], [keyB, productB]) =>
          // TODO: fix types
          new Date(productA.startsAt) - new Date(productB.startsAt)
      )
      .map(([key]) => key),
  increaseGroupSize: (product: ProductKey) =>
    set(state => ({
      products: {
        ...state.products,
        [product]: {
          ...state.products[product],
          groupSize: state.products[product].groupSize + 1,
        },
      },
    })),
  decreaseGroupSize: (product: ProductKey) =>
    set(state => ({
      products: {
        ...state.products,
        [product]: {
          ...state.products[product],
          groupSize: state.products[product].groupSize - 1,
        },
      },
    })),
}))
