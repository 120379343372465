import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
// eslint-disable-next-line
import { add, parseISO, format, differenceInMinutes, isBefore } from 'date-fns'
// DateFns ts Error with multiple import https://github.com/date-fns/date-fns/issues/1677
// eslint-disable-next-line
import { cs, enGB } from 'date-fns/locale'
import { Slot } from '../types'

export enum DateFormat {
  Date = 'dd-MM-yyyy',
  Time = 'HH:mm',
  DateTime = 'dd-MM-yyyy HH:mm',
  DayMonthYear = 'do LLL y',
}

export function formatDateTime(
  date: Date | string | undefined,
  dateFormat: string,
  locale?: string
): string {
  const lang = locale === 'cs' ? cs : enGB

  if (!date) return ''

  if (typeof date === 'string')
    return formatInTimeZone(parseISO(date), 'Europe/Prague', dateFormat, { locale: lang })

  return formatInTimeZone(date, 'Europe/Prague', dateFormat, { locale: lang })
}

export const getFullDate = (slotDate: Date, locale?: string, includeTime?: boolean) => {
  // for some reason, the string got passed here, altough Date is typed correctly everywhere
  const date = typeof slotDate === 'string' ? parseISO(slotDate) : slotDate

  const lang = locale === 'cs' ? cs : enGB
  // TODO: for some reason the string is passed altough i have typed it as a Date therefore i have to parseISO
  const dayOfWeek = format(date, 'EEEE', { locale: lang })
  const formattedDate = format(date, DateFormat.DayMonthYear, { locale: lang })

  return `${formattedDate}, ${dayOfWeek},  ${
    includeTime ? formatDateTime(date, DateFormat.Time) : ''
  }`
}

export const minDatePickerDate = new Date()

export const maxDatePickerDate = add(new Date(), { years: 2 })

// Pass endOfDay or startOfDay and this function ensures that the date fns functions come with right formatt. Otherwise if you pass already TZed date, these functions will convert the date back to UTC.
// https://stackoverflow.com/questions/67819591/how-to-use-startofday-from-date-fns-with-timezones
export const calcZonedDate = (date: Date, tz: string, fn: any, options = null) => {
  const inputZoned = utcToZonedTime(date, tz)
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned)
  return zonedTimeToUtc(fnZoned, tz)
}

export const calculateBufferCondition = (
  checkedSlot: Slot,
  chosenSlot: Slot[],
  increasedCapacity: boolean
): boolean => {
  const experimentIsChosenFirst = chosenSlot[0]?.product === 'EXPERIMENT'
  if (!chosenSlot) return true

  const firstBuffer = increasedCapacity ? 50 : 60
  const secondBuffer = increasedCapacity ? 35 : 45

  return chosenSlot.every(chosenSlot => {
    const diffBetweenEnds = Math.abs(
      differenceInMinutes(Date.parse(checkedSlot.endDate), Date.parse(chosenSlot.endDate))
    )
    const diffBetweenStarts = Math.abs(
      differenceInMinutes(Date.parse(checkedSlot.date), Date.parse(chosenSlot.date))
    )
    // BUFFER BETWEEN START TIMES DEPENDS ON WHETHER THE EXPERIMENT IS BEFORE THE DUNGEON OR NOT.
    // CASE 1 EXPERIMENT IS BEFORE THE DUNGEON = 60
    // CASE 2 EXPERIMENT IS AFTER DUNGEON = 45
    // I NEED TO DIFFERENTIATE WHICH PRODUCT WAS CHOSEN FIRST TO RUN THE BEFORE FUNCTION WITH THE CORRECT DATES
    const experimentIsBeforeDungeon = experimentIsChosenFirst
      ? isBefore(Date.parse(chosenSlot.date), Date.parse(checkedSlot.date))
      : isBefore(Date.parse(checkedSlot.date), Date.parse(chosenSlot.date))

    const bufferBetweenStart = experimentIsBeforeDungeon ? firstBuffer : secondBuffer
    const bufferBetweenEnds = 15

    return diffBetweenEnds >= bufferBetweenEnds && diffBetweenStarts >= bufferBetweenStart
  })
}

export const datesAreHourApart = (slotDate: string, otherProductSlotDates: Slot[]): boolean => {
  // TODO: this might not be nedessary guard
  if (!otherProductSlotDates) return true

  return otherProductSlotDates.every(slot => {
    const diffInMinutes = Math.abs(Date.parse(slotDate) - Date.parse(slot.date)) / (1000 * 60)
    return diffInMinutes >= 60
  })
}
